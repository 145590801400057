/* Using alpha version of universal HB because hb server doesn't work since next 9.6+ */
import Honeybadger from '@honeybadger-io/js';

const config: any = {
  apiKey: process.env.HONEYBADGER_API_KEY,
  environment: process.env.HONEYBADGER_ENV,
  revision: process.env.HONEYBADGER_REVISION,
  onerror: false,
  // Docs outdated https://github.com/honeybadger-io/honeybadger-js/blob/master/src/browser.ts#L133
  enableUncaught: false,
};

export default Honeybadger.configure(config);
